const CONFIGURATION = {
    authorizedDomains: ['localhost'],
    defaultTheme: 'dpasa-dark',
    apps: {
        dpasa: {
            enabled: true,
            url: 'https://app.kalk.ai',
        },
        mipasa: {
            enabled: false,
        },
        unbounded: {
            enabled: false,
        },
        papillon: {
            enabled: false,
        },
        pasa: {
            enabled: false,
        },
    },
    oidc: {
        github: true,
        google: true,
        ethereum: true,
    },
    sentry: {
        enabled: false,
    },
};
export default CONFIGURATION;
