const CONFIGURATION = {
    defaultTheme: 'dpasa-dark',
    authorizedDomains: ['kalk.ai'],
    apps: {
        dpasa: {
            enabled: true,
            url: 'https://app.kalk.ai',
        },
        mipasa: {
            enabled: false,
        },
        unbounded: {
            enabled: false,
        },
        papillon: {
            enabled: false,
        },
        pasa: {
            enabled: false,
        },
    },
    oidc: {
        github: true,
        google: true,
        ethereum: true,
    },
    sentry: {
        enabled: true,
        dsn: 'https://a7607287ba53453897b34276cb967443@o952724.ingest.sentry.io/6753216',
        tunnel: '/tunnel',
        environment: 'Kalk',
    },
};
export default CONFIGURATION;
